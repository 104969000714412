import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import { mapGetters } from "vuex";
import { SexRadio } from "@/interface/pagas/mine";
import {
  ChangeInfoReq,
  ChangePasswordReq,
  ChangePhoneReq,
  NewPhoneReq,
  UnboundReq,
  VerificationCodeReq
} from "@/interface/res/mine";
import {
  ChangeInfoApi,
  ChangePasswordApi, ChangePhoneApi,
  SmsChangePasswordApi, SmsChangePhoneApi, SmsNewPhoneApi,
  SmsValidationChangePasswordApi, SmsValidationChangePhoneApi, UnboundApi
} from "@/network/modules/mine";
import {email, pass, phone} from "@/utils/regExp";
import {File} from "@/interface/pagas/common";

@Component({
  components: {
    common
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
})
export default class Account extends Vue {
  @Provide() imgLoading:boolean = false

  @Provide() form:ChangeInfoReq = {
    nickName:'',
    realName:'',
    birthday:'',
    sex:0,
    email:'',
    avatar:'',
  }
  @Provide() sexData:Array<SexRadio> = [
    {label:'男',value:0},
    {label:'女',value:1},
    {label:'保密',value:2},
  ]
  @Provide() formLoading:boolean = false


  @Provide() nickNameMsg:string = ""
  @Provide() realNameMsg:string = ""
  @Provide() emailMsg:string = ""
  @Provide() msgType:string = "err"
  @Provide() infoShow:boolean = false
  @Provide() loading:boolean = false

  @Provide() safeTitle:string = ''
  @Provide() safeConfirmBtn:string = ''
  @Provide() safeCancelShow:boolean = false
  @Provide() safeShow:boolean = false
  @Provide() safeLoading:boolean = false

  //1-修改账号 2-修改手机
  @Provide() safeType:number = 0
  //1-发送验证码 2-验证验证码 3-修改密码 4-输入新手机号码 5-新手机号码验证 6-完成修改
  @Provide() safeStep:number = 1
  @Provide() code:string = ''
  @Provide() codeTime:any = null
  @Provide() codeMsg:string = ''
  @Provide() codeNum:number = 60

  @Provide() passForm:ChangePasswordReq = {
    password:'',
    passwordAgain:'',
  }
  @Provide() passMsg:string = ''
  @Provide() passMsgType:string = 'err'

  @Provide() newPhone:string = ''
  @Provide() newPhoneMsg:string = ''

  @Provide() newPhoneCode:string = ''
  @Provide() phoneCodeMsg:string = ''
  @Provide() phoneCodeNum:number = 60
  @Provide() phoneCodeTime:any = null


  @Provide() newWindow:any = null
  @Provide() wechatUntieShow:boolean = false
  @Provide() wechatUntieLoading:boolean = false


  @Watch('userInfo',{immediate:false, deep:false})
  @Watch('safeStep',{immediate:true, deep:false})
  private iDataChange():void {
    const _this:any = this;
    switch (_this.safeStep) {
      case 1:
        _this.safeCancelShow = false
        _this.safeConfirmBtn = '确定'
        _this.safeTitle = '针情空间账号安全验证'
        break;
      case 2:
        _this.safeCancelShow = false
        _this.safeConfirmBtn = '确定'
        _this.safeTitle = '针情空间账号安全验证'
        break;
      case 3:
        _this.safeCancelShow = false
        _this.safeConfirmBtn = '确定'
        _this.safeTitle = '修改密码'
        break;
      case 4:
        _this.safeCancelShow = false
        _this.safeConfirmBtn = '下一步'
        _this.safeTitle = '修改安全手机'
        break;
      case 5:
        _this.safeCancelShow = true
        _this.safeConfirmBtn = '确定'
        _this.safeTitle = '修改安全手机'
        break;
      case 6:
        _this.safeCancelShow = false
        _this.safeConfirmBtn = '确定'
        _this.safeTitle = '修改安全手机'
        break;
    }

  }


  created(){
    const _this:any = this;
    _this.resetInfoFn()
  }

  async UnboundFn(){
    const _this:any = this;
    _this.wechatUntieLoading = true
    let params:UnboundReq = {
      source:"WECHAT"
    }
    const res = await UnboundApi(params)
    let a = setTimeout(()=>{_this.wechatUntieLoading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.userInfo.wechatBind = false
    _this.wechatUntieShow = false
    _this.$store.commit('SET_USETINFO',JSON.stringify(_this.userInfo))
    _this.$message.success('已解除');
  }

  /**
   * @Author HS
   * @Date 2021/8/25 6:26 下午
   * @Description: 绑定微信
   * @Params: null
   * @Return: null
  */
  makeNewWindow() {
    const _this:any = this;

    if(_this.newWindow)_this.newWindow.close()


    if (!_this.newWindow || _this.newWindow.closed) {

      let customWidth = 900;
      let customHeight = 650;
      let iTop = (window.screen.height - 30 - customHeight) / 2; //获得窗口的垂直位置;
      let iLeft = (window.screenX || window.screenLeft || 0) + (screen.width - customWidth) / 2; //获得窗口的水平位置;
      let windowFeatures = 'height=' + customHeight + ',innerHeight=' + customHeight + ',width=' + customWidth + ',innerWidth=' +
        customWidth + ',top=' + iTop + ',left=' + iLeft +
        ',toolbar=no,menubar=no,scrollbars=no,resizeable=no,location=no,status=no'
      // this.newWindow = window.open(`${process.env.VUE_APP_BASE_URL}/shop/account/oauth/bind/web?source=wechat_open&accountId=`+_this.userInfo.accountId, "", windowFeatures);
      this.newWindow = window.open(`${process.env.VUE_APP_BASE_URL}/shop/account/oauth/bind/web?source=wechat_open&accountId=`+_this.userInfo.accountId, "_blank");

      let loop = setInterval(()=>{
        if(_this.newWindow.closed) {
          _this.$store.dispatch('GetInfoFn',_this)
          clearInterval(loop);//停止定时器
        }
      }, 1000);

    } else {
      _this.newWindow.focus();
    }
  }


  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: 页面跳转
   * @Params: { string ： path - true } [path：路径]
   * @Return:
  */
  goRouter(path:string){
    const _this:any = this;
    _this.$base.goRouter(_this.$LoginConfig[path].path)
  }

  /**
   * @Author HS
   * @Date 2021/7/5 8:47 下午
   * @Description: 弹窗修改头像
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  handleAvatarSuccess(response:File) {
    const _this:any = this
    _this.form.avatar = response.data
    let a = setTimeout(()=>{_this.formLoading = false; clearTimeout(a)},500)
  }
  /**
   * @Author HS
   * @Date 2021/7/5 8:47 下午
   * @Description: 修改头像
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
  */
  async avatarChangeFn(response:File){
    const _this:any = this
    let params:ChangeInfoReq = _this.userInfo
    params.avatar = response.data
    const res = await ChangeInfoApi(params)
    let a = setTimeout(()=>{_this.imgLoading = false; clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.$base.setUserInfo(params)
  }


  /**
   * @Author HS
   * @Date 2021/7/6 10:11 上午
   * @Description: 选择性别
   * @Params: { number ： val - true } [值]
   * @Return: null
  */
  changeSexFn(val:number){
    const _this:any = this;
    _this.form.sex = val
  }
  /**
   * @Author HS
   * @Date 2021/7/7 9:28 上午
   * @Description: 个人信息弹窗打开
   * @Params: null
   * @Return: null
  */
  infoOpenFn(){
    const _this:any = this
    _this.resetInfoFn()
    _this.infoShow = true
  }
  /**
   * @Author HS
   * @Date 2021/7/7 9:28 上午
   * @Description: 重置个人信息
   * @Params: null
   * @Return: null
  */
  resetInfoFn(){
    const _this:any = this
    const {nickName, realName, birthday, sex, email, avatar} = _this.userInfo
    _this.$base.isNull(nickName) ?_this.form.nickName = "" : _this.form.nickName = nickName
    _this.$base.isNull(realName) ?_this.form.realName = "" : _this.form.realName = realName
    _this.$base.isNull(birthday) ?_this.form.birthday = "" : _this.form.birthday = birthday
    _this.$base.isNull(email) ?_this.form.email = "" : _this.form.email = email
    _this.$base.isNull(avatar) ?_this.form.avatar = "" : _this.form.avatar = avatar
    _this.form.sex = sex
  }

  /**
   * @Author HS
   * @Date 2021/7/5 8:34 下午
   * @Description: 修改个人信息
   * @Params: null
   * @Return: null
  */
  async changeInfoFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.form.nickName)){
      _this.nickNameMsg = "昵称不能为空"
      return
    }
    _this.nickNameMsg = ""


    if(!_this.$base.isNull(_this.form.email)){

      if(!email.test(_this.form.email)){
        _this.msgType = "err"
        _this.emailMsg = "请输入正确的邮箱"
        return
      }
    }
    _this.emailMsg = ""
    _this.loading = true
    let params:ChangeInfoReq = _this.form
    const res = await ChangeInfoApi(params)
    let a = setTimeout(()=>{_this.loading = false; clearTimeout(a)},500)
    const { code } = res
    if(code != 200 ){
      _this.emailMsg = res.msg
      return
    }
    _this.msgType = "success"
    _this.emailMsg = "修改完成"
    _this.$base.setUserInfo(_this.form)
    let b = setTimeout(()=>{
      _this.infoShow = false;
      _this.emailMsg = ""
      clearTimeout(b);
    },500)



  }

  /**
   * @Author HS
   * @Date 2021/7/6 3:56 下午
   * @Description: 安全资料修改弹窗
   * @Params: { number ： iType - true } [类型：1-账号密码 2-安全手机]
   * @Return: null
  */
  safeShowFn(iType:number){
    const _this:any = this;
    _this.safeType = iType
    _this.safeStep = 1
    _this.safeShow = true
    _this.passMsgType = "err"
    _this.phoneCodeMsg =
      _this.newPhoneCode =
        _this.newPhone =
          _this.newPhoneMsg =
            _this.passForm.passwordAgain =
              _this.passForm.password =
                _this.passMsg =
                  _this.codeMsg =
                    _this.code = ""
  }

  /**
   * @Author HS
   * @Date 2021/7/6 10:16 上午
   * @Description: 安全验证回调
   * @Params: null
   * @Return: null
  */
  async safeFn(){
    const _this:any = this;
    switch (_this.safeStep) {
      case 1:
        _this.sendCodeFn()
        break;
      case 2:
        _this.oldPhoneCodeFn()
        break;
      case 3:
        _this.newPassEditFn()
        break;
      case 4:
        _this.newPhoneSendCodeFn()
        break;
      case 5:
        _this.newPhoneEditFn()
        break;
      case 6:
        _this.safeShow = false
        break;

    }
  }
  /**
   * @Author HS
   * @Date 2021/7/6 5:42 下午
   * @Description: 发送验证码
   * @Params: null
   * @Return: null
  */
  async sendCodeFn(){
    const _this:any = this;
    _this.safeLoading = true
    let res = {code:0,msg:"",data:""}
    switch (_this.safeType){
      case 1:
        res = await SmsChangePasswordApi()
        break;
      case 2:
        res = await SmsChangePhoneApi()
        break;
    }
    _this.safeLoadingHide()
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    let b = setTimeout(()=>{
      _this.codeNum = 59
      _this.countdownFn()
      _this.safeStep = 2
      clearTimeout(b)
    },500)
  }
  /**
   * @Author HS
   * @Date 2021/7/6 4:15 下午
   * @Description: 原手机号码验证
   * @Params: null
   * @Return: null
  */
  async oldPhoneCodeFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.code)){
      _this.codeMsg = "请输入验证码"
      return
    }

    _this.safeLoading = true
    let params:VerificationCodeReq = {
      phoneCode : _this.code
    }
    let res = {code:0,msg:"",data:""}
    switch (_this.safeType){
      case 1:
        res = await SmsValidationChangePasswordApi(params)
        break;
      case 2:
        res = await SmsValidationChangePhoneApi(params)
        break;
    }
    _this.safeLoadingHide()
    const { code , msg } = res
    if(code != 200 ){
      _this.codeMsg = msg
      return
    }
    let b = setTimeout(()=>{
      switch (_this.safeType){
        case 1:
          _this.safeStep = 3
          break;
        case 2:
          _this.safeStep = 4
          break;
      }
      clearTimeout(b)
    },500)
  }

  /**
   * @Author HS
   * @Date 2021/7/6 6:07 下午
   * @Description: 新手机号码发送验证码
   * @Params: null
   * @Return: null
  */
  async newPhoneSendCodeFn(){
    const _this:any = this;
    if(_this.$base.isNull(_this.newPhone)){
      _this.newPhoneMsg = "请输入手机号码"
      return
    }

    if(!phone.test(_this.newPhone)){
      _this.newPhoneMsg = "请输入正确的手机号码"
      return
    }

    if(_this.newPhone == _this.userInfo.phone){
      _this.newPhoneMsg = "此号码和当前绑定号码相同"
      return
    }

    _this.safeLoading = true
    let params:NewPhoneReq = {
      newPhone:_this.newPhone
    }
    const res = await SmsNewPhoneApi(params)
    _this.safeLoadingHide()
     const { code , msg } = res
    if(code != 200 ){
      _this.newPhoneMsg = msg
      return
    }
    _this.newPhoneMsg = ""
    let b = setTimeout(()=>{
      _this.safeStep = 5
      _this.phoneCodeNum = 59
      _this.countdownPhoneFn()
      clearTimeout(b)
    },500)
  }

  /**
   * @Author HS
   * @Date 2021/7/6 6:10 下午
   * @Description: 修改安全手机
   * @Params: null
   * @Return: null
  */
  async newPhoneEditFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.newPhoneCode)){
      _this.phoneCodeMsg = "请输入验证码"
      return
    }

    _this.safeLoading = true;
    let params:ChangePhoneReq = {
      newPhone:_this.newPhone,
      newPhoneCode:_this.newPhoneCode,
    }
    const res = await ChangePhoneApi(params)
    _this.safeLoadingHide()
    const { code , msg } = res
    if(code != 200 ){
      _this.phoneCodeMsg = msg
      return
    }
    _this.phoneCodeMsg = ""
    _this.$base.setUserInfo({phone:_this.newPhone})
    let b = setTimeout(()=>{
      _this.safeStep = 6
      clearTimeout(b)
    },500)
  }


  /**
   * @Author HS
   * @Date 2021/7/6 4:26 下午
   * @Description: 修改密码
   * @Params: null
   * @Return: null
  */
  async newPassEditFn(){
    const _this:any = this;

    if(_this.$base.isNull(_this.passForm.password)){
      _this.passMsgType = "err"
      _this.passMsg = "密码不能为空"
      return
    }
    _this.passMsg = ""

    if(!pass.test(_this.passForm.password)){
      _this.passMsgType = "err"
      _this.passMsg = "仅限8~16位，数字、字母、字符至少包含两种"
      return
    }

    if(_this.passForm.password != _this.passForm.passwordAgain){
      _this.passMsgType = 'err'
      _this.passMsg = "前后密码不一致"
      return
    }
    _this.safeLoading = true
    let params:ChangePasswordReq = _this.passForm
    params.password = _this.$adminEncryptBy(params.password)
    params.passwordAgain = _this.$adminEncryptBy(params.passwordAgain)
    const res = await ChangePasswordApi(params)
    _this.safeLoadingHide()
    const { code , msg } = res
    if(code != 200 ){
      _this.passMsgType = 'err'
      _this.passMsg = msg
      return
    }
    _this.passMsgType = "success"
    _this.passMsg = "修改完成"
    let b = setTimeout(()=>{
      _this.safeShow = false
      clearTimeout(b)
    },1500)
  }



  /**
   * @Author HS
   * @Date 2021/7/6 3:55 下午
   * @Description: 倒计时
   * @Params: null
   * @Return: null
  */
  countdownFn(){
    const _this:any = this
    clearTimeout(_this.codeTime)
    _this.codeTime = setTimeout(()=>{
      _this.codeNum--
      if(_this.codeNum > 0){
        clearTimeout(_this.codeTime)
        _this.countdownFn()
        return
      }
      _this.codeNum = 60
      clearTimeout(_this.codeTime)
    }, 1000);
  }

  countdownPhoneFn(){
    const _this:any = this
    clearTimeout(_this.phoneCodeTime)
    _this.phoneCodeTime = setTimeout(()=>{
      _this.phoneCodeNum--
      if(_this.phoneCodeNum > 0){
        clearTimeout(_this.phoneCodeTime)
        _this.countdownPhoneFn()
        return
      }
      _this.phoneCodeNum = 60
      clearTimeout(_this.phoneCodeTime)
    }, 1000);
  }

  /**
   * @Author HS
   * @Date 2021/7/6 6:14 下午
   * @Description: 隐藏loading
   * @Params: null
   * @Return: null
  */
  safeLoadingHide(){
    const _this:any = this
    let a = setTimeout(()=>{_this.safeLoading = false;clearTimeout(a)},500)
  }

  /**
   * @Author HS
   * @Date 2021/7/6 6:39 下午
   * @Description: 退出登录
   * @Params: null
   * @Return: null
  */
  loginOutFn(){
    const _this:any = this;
    _this.$store.dispatch('LoginOutFn',_this)
  }

}
